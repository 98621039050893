import LoginImg from "../../assets/images/login-img.png";
import Logo from "../../assets/images/ai-logo.png";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import WebService from "../../Services/WebService";
import { useEffect, useState } from "react";
import { HiOutlineArrowRight } from "react-icons/hi2";
import FullPageLoader from "../Common/FullPageLoader/FullPageLoader";
import TableLoader from "../Common/TableLoader/TableLoader";
import { clientId, tenantId } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";

const MyClients = () => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const dispatch = useDispatch();
    const [myClients, setMyClients] = useState<any>();
    const [fullpageLoader, setFullpageLoader] = useState<any>(false);
    const [loader, setLoader] = useState<any>(false);

    const navigate = useNavigate();

    useEffect(() => {
        getClients();
    }, []);

    const getClients = () => {
        setLoader(true)
        return WebService.getAPI({
            action: `my-clients`,
            body: null,
            isShowError: false,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                if (res.list?.length !== 1) {
                    setMyClients(res.list);
                    setLoader(false);
                }
                else {
                    setLoader(false);
                    setFullpageLoader(true);
                    dispatch(clientId(res?.list[0]?.uuid));
                    dispatch(tenantId(res?.list[0]?.tenantId));
                    localStorage.setItem('ucd', btoa(JSON.stringify(res?.list[0])));
                    setFullpageLoader(false);
                    navigate("/overview");
                }
            })
            .catch((error: any) => {
                setLoader(false);
                return error;
            });
    };

    const selectClient = (client: any) => {
        dispatch(clientId(client.uuid));
        dispatch(tenantId(client.tenantId));
        // localStorage.setItem("client_id", client.uuid);
        // localStorage.setItem("t_id", client.tenantId);
        localStorage.setItem('ucd', btoa(JSON.stringify(client)));
        setFullpageLoader(true);
        setTimeout(() => {
            setFullpageLoader(false);
            navigate("/overview");
        }, 1000);
    }


    return (
        <>
            {
                !fullpageLoader && myClients?.length > 1
                    ? <div className="login-page">
                        <div className="row g-0">
                            <div className="col-lg-7">
                                <img src={LoginImg} className="login-img" alt="" />
                            </div>
                            <div className="col-lg-5 d-flex align-items-center justify-content-center">
                                <div className="mb-3 w-100">
                                    <div className="px-lg-5 mb-3">
                                        <div className="mb-4">
                                            <img src={Logo} width="69" alt="Logo" />
                                        </div>
                                        <div className="">
                                        </div>
                                        {
                                            !loader
                                                ? <div className="card p-4 mb-4">
                                                    <h4 className="text-brand mb-4 ">Select Account</h4>
                                                    {
                                                        myClients?.map((client: any, index: any) => {
                                                            return (
                                                                <div className="border bg-light-blue mb-2 cursor-pointer" onClick={() => selectClient(client)}>
                                                                    <a className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                        <span>{client?.name}</span>
                                                                        <span className="font-22"> <HiOutlineArrowRight size={18} className="icon ms-1" /><i className="bi bi-arrow-right-short"></i></span>
                                                                    </a>
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                </div>
                                                : <TableLoader />
                                        }
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                    : <FullPageLoader show={true} />
            }
        </>
    );
};
export default MyClients;
