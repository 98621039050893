import { useEffect, useState } from "react";
import HelperService from "../../Services/HelperService";
import WebService from "../../Services/WebService";
import {
  Button,
  Card,
  FloatingLabel,
  Form,
  Modal,
  Offcanvas,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import FullPageLoader from "../Common/FullPageLoader/FullPageLoader";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const FlaggedEmailPreview = () => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  var watchVariable = watch();
  const [emailData, setEmailData] = useState<any>({});
  const [attachments, setAttachments] = useState<any>([]);
  const [reviewFlag, setReviewFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  let params = useParams();
  useEffect(() => {
    if (params.id && params.id !== "") {
      getEmailDetail(params.id);
    }
  }, []);
  const getEmailDetail = (id: any) => {
    setLoader(true);
    return WebService.getAPI({
      action: `email?id=` + id,
      body: null,
      isShowError: true,
      access_token:access_token,
      t_id:t_id
    })
      .then((res: any) => {
        setEmailData(res);
        setAttachments(res?.attachments);
        setLoader(false);
      })
      .catch((error: any) => {
        setLoader(false);
        return error;
      });
  };
  const handleAddNotes = () => {
    watchVariable.id = params.id;
    WebService.addLoader("save")
    return WebService.putAPI({
      action: `email`,
      body: watchVariable,
      isShowError: true,
      access_token:access_token,
      t_id:t_id
    })
      .then((res: any) => {
        toast.success(res.message);

        WebService.removeLoader("save");
      })
      .catch((error: any) => {
        toast.error(error.message);
        WebService.removeLoader("save");
        return error;
      });
  };
  const handleError = () => {};
  return (
    <>
      {!loader ? (
        <>
          <div className="border-bottom px-3">
            <div className="d-flex justify-content-between">
              <p className="font-bold font-16 mb-0">{emailData?.subject}</p>
              <p className="mb-0 font-14">
                Date:{" "}
                {HelperService.getFormatedDateForDetail(emailData?.dateTime)}
              </p>
            </div>

            <p className="mb-0 font-14">From: {emailData?.fromEmail}</p>
            <p className=" font-14">To: {emailData?.toEmail}</p>
          </div>
          <iframe
            className="px-3 font-14 text-secondary w-100"
            style={{ minHeight: "500px", minWidth: "100%" }}
            srcDoc={emailData?.content}
          ></iframe>
          <Form
            className="w-100 form-style h-100"
            name="Verify"
            id="Verify"
            onSubmit={handleSubmit(handleAddNotes, handleError)}
          >
            <div style={{ maxWidth: "640px" }} className="mx-auto my-4">
              <div className="px-3 mb-3">
                <div className="d-flex gap-2 mb-1">
                  <div className="px-2 py-1 bg-danger-subtle">
                    <Form.Check
                      {...register("markedAsFlag")}
                      type="checkbox"
                      id="Flag"
                      label="Flag"
                      className="labe-text-dark checkbox-danger"
                      checked={watchVariable.markedAsFlag}
                      onChange={() => {
                        if (watchVariable.markedAsFlag) {
                          watchVariable.markedAsFlag = false;
                          setValue("markedAsFlag", false);
                          setReviewFlag(false);
                        } else {
                          watchVariable.markedAsFlag = true;
                          setValue("markedAsFlag", true);
                        }
                      }}
                    />
                  </div>
                  <div className="px-2 py-1 bg-success-subtle">
                    <Form.Check
                      type="checkbox"
                      {...register("markedAsView")}
                      id="MarkViewed"
                      label="Mark as viewed"
                      className="labe-text-dark checkbox-success"
                      checked={watchVariable.markedAsView}
                      onChange={() => {
                        if (watchVariable.markedAsView) {
                          watchVariable.markedAsView = false;
                          setValue("markedAsView", false);
                          setReviewFlag(false);
                        } else {
                          watchVariable.markedAsView = true;
                          setValue("markedAsView", true);
                        }
                      }}
                    />
                  </div>

                  <div className="px-2 py-1 bg-warning-subtle">
                    <Form.Check
                      type="checkbox"
                      {...register("escalateAndSend")}
                      id="EscalateSend"
                      label="Escalate send"
                      className="labe-text-dark checkbox-warning"
                      checked={watchVariable.escalateAndSend}
                      onChange={() => {
                        if (watchVariable.escalateAndSend) {
                          watchVariable.escalateAndSend = false;
                          setValue("escalateAndSend", false);
                          setReviewFlag(false);
                        } else {
                          watchVariable.escalateAndSend = true;
                          setValue("escalateAndSend", true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className=" border-secondary-subtle" />
              <div className=" form-style">
                <div className="px-3">
                  <FloatingLabel controlId="Notes" label="Notes">
                    <Form.Control
                      {...register("notes")}
                      as="textarea"
                      placeholder="Notes"
                      style={{ height: "100px" }}
                      value={watchVariable.notes ? watchVariable.notes : ""}
                    />
                  </FloatingLabel>
                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-brand-1 w-100"
                      id="save"
                    >
                      Add Note
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </>
      ) : (
        <FullPageLoader />
      )}
    </>
  );
};
export default FlaggedEmailPreview;
