import { useState } from "react";
import { InputGroup } from "react-bootstrap";
import { HiOutlineKey } from "react-icons/hi2";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "./Password.css";

interface PropData {
  id?: string;
  name?: string;
  placeholder: string;
  label: string;
  inputGroupClassName?: string;
  inputFieldClassName?: string;
  method?: any;
  requiredMsg?: any;
  key?: any;
  onError?: any; 

}

const Password = (props: PropData) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <InputGroup className={props.inputGroupClassName}>
        <div className="input-group mb-2">
          <span className="input-group-text bg-white border-end-0 text-secondary">
            <HiOutlineKey size={16} />
          </span>
          <input
            id={props.id}
            className={props.inputFieldClassName}
            name={props.name}
            placeholder={props.placeholder}
            {...props.requiredMsg}
            type={passwordVisible ? "text" : "password"}
            onChange={props.method}
            autoComplete="new-password" />
          <span
            className="input-group-text text-secondary bg-white border-start-0"
            onClick={handlePasswordVisibility}
          >
            {passwordVisible ? <BsEyeSlash size={16} /> : <BsEye size={16} />}
          </span>
        </div>
      </InputGroup>
      {props.onError ? props.onError : ""}
    </>
  );
};

export default Password;
