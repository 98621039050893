import { useEffect, useState } from "react";
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './WMDateRangePicker.css'
import HelperService from "../../../Services/HelperService";
import { AiFillCalendar } from "react-icons/ai"
import { startOfYear } from "date-fns/fp";
import { addYears, endOfDay, endOfYear, isSameDay, subDays } from "date-fns";

interface PropData {
  date_range: any;
  selectData: any
  color?: string
}

const WMDateRangePicker = (props: PropData) => {
  const [showPicker, setShowPicker] = useState(false);
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const getDateChangeEvent = (ranges: any) => {
    setState([{
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: 'selection'
    }])
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      var dataRange = {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: 'selection'
      };
      setShowPicker(false)
      props.selectData(dataRange);
    }
  }
  useEffect(() => {
    document.addEventListener('mouseup', function (e: any) {
      var container = document.getElementById('wbDateRangPicker');
      if (!container?.contains(e.target)) {
        setShowPicker(false);
      }
    }.bind(this));
  }, [])


  useEffect(() => {
    var newDateRange = {
      startDate: new Date(props.date_range.startDate),
      endDate: new Date(props.date_range.endDate),
      key: 'selection'
    }
    setState([newDateRange]);
  }, [props.date_range])

  return (
    <div id="wbDateRangPicker" className="wmdateRangePickerClass">
      {showPicker ? <> <p className={'mb-0 font-14  mClass ' + (props.color !== "" ? props.color : "text-black")} onClick={() => { setShowPicker(true) }}><AiFillCalendar size={18} className="icon" />{" "}{HelperService.getFormatedDateForDetail(state[0].startDate, "MMM D, YYYY")} - {HelperService.getFormatedDateForDetail(state[0].endDate, "MMM D, YYYY")}</p> <DateRangePicker
        onChange={getDateChangeEvent}
        months={2}
        ranges={state}
        direction="horizontal"
        showPreview={false}
        dragSelectionEnabled={false}
        rangeColors={["var(--gradient-bg, linear-gradient(52deg, #FF7526 0%, #FF0092 47.29%, #6F00FF 100%))"]}
        color={"#6F00FF"}
        staticRanges={[
          ...defaultStaticRanges,
          {
            label: "This year",
            range: () => ({
              startDate: startOfYear(new Date()),
              endDate: endOfDay(new Date())
            }),
            isSelected(range: any) {
              return (
                isSameDay(range.startDate, startOfYear(new Date())) &&
                isSameDay(range.endDate, endOfDay(new Date()))
              );
              // return (
              //   false &&
              //   false
              // );
            }
          },
          {
            label: "Last year",
            range: () => ({
              startDate: startOfYear(addYears(new Date(), -1)),
              endDate: endOfYear(addYears(new Date(), -1))
            }),
            isSelected(range: any) {
              return (
                isSameDay(range.startDate, startOfYear(addYears(new Date(), -1))) &&
                isSameDay(range.endDate, endOfYear(addYears(new Date(), -1)))
              );
            }
          }
        ]}
      /></> : <><p className={'mb-0 font-14 dashboard-date-range  mClass ' + (props.color !== "" ? props.color : "text-black")} onClick={() => { setShowPicker(true) }}><AiFillCalendar size={18} className="icon" />{" "}{HelperService.getFormatedDateForDetail(state[0].startDate, "MMM D, YYYY")} - {HelperService.getFormatedDateForDetail(state[0].endDate, "MMM D, YYYY")}</p></>}

    </div>
  );
};

export default WMDateRangePicker;
