import { useEffect, useState } from "react";
import { Col, Card, Button, Offcanvas } from "react-bootstrap";
import { HiArrowLongRight } from "react-icons/hi2";
import NoDataFound from "../../assets/images/empty_item.svg";
import NoImage from "../../assets/images/noImage.png";
import fbLogo from "../../assets/images/socialChannels/FACEBOOK.svg";
import XLogo from "../../assets/images/socialChannels/twitter_icon.svg";
import instaLogo from "../../assets/images/socialChannels/insta-logo.svg";
import linkdnLogo from "../../assets/images/socialChannels/linkedin-logo.svg";
import smsLogo from "../../assets/images/socialChannels/sms-icon.svg";
import webLogo from "../../assets/images/socialChannels/web-icon.svg";
import ZoomLogo from "../../assets/images/zoom-logo.svg";
import TeamsLogo from "../../assets/images/ms-team-icon.svg";
import EmailLogo from "../../assets/images/email-archive-icon.svg";
import WebService from "../../Services/WebService";
import HelperService from "../../Services/HelperService";
// import ConnectBlade from "./ConnectBlade";
// import "./integration.scss";
import TableLoader from "../Common/TableLoader/TableLoader";
import DeleteModal from "../Common/DeleteModal/DeleteModal";
import { TooltipCustom } from "../Common/Tooltip/Tooltip";
import { TbCloudUpload } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../PageTitle";
import { useSelector } from "react-redux";

interface PropsData {
    // client: any;
    // tab?: any;
    // currentSubscriptionList?: any
}
const ExportData = (props: PropsData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const params = useParams();
    const navigate = useNavigate();
    const [action, setAction] = useState("");
    const [showDelete, setShowDelete] = useState(false);

    const [showConnect, setShowConnect] = useState(false);
    const handleShowConnect = () => {
        setShowConnect(true);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loader, setLoader] = useState(false);
    const [integrationDetailList, setIntegrationDetailsList] = useState<any[]>(
        []
    );
    const [integrationSocialDetailList, setIntegrationSocialDetailsList] =
        useState<any[]>([]);
    // eslint-disable-next-line
    const [currentSubscriptionList, setCurrentSubscriptionList] = useState<any>(
        []
    );
    const [deleteKeywordList, setDeleteKeywordList] = useState<any[]>([]);
    const [allSelect, setAllSelect] = useState(false);
    const [smsKeywordList, setSmsKeywordList] = useState<any[]>([]);


    const [totalConnectors, setTotalConnector] = useState<any>(0);
    const [totalSms, setTotalSms] = useState<any>(0);
    const [totalWebsite, setTotalWebsite] = useState<any>(0);
    const [totalEmail, setTotalEmail] = useState<any>(0);

    const [totalConnectorsUsed, setTotalConnectorUsed] = useState<any>(0);
    const [totalSmsUsed, setTotalSmsUsed] = useState<any>(0);
    const [totalEmailUsed, setTotalEmailUsed] = useState<any>(0);
    const [totalWebsiteUsed, setTotalWebsiteUsed] = useState<any>(0);

    useEffect(() => {
        // getConnectedAccounts()
        // getAllIntegrationList()
    }, []);

    const deleteList = (isDelete: any) => {
        if (isDelete) {
            getAllIntegrationList();
            getConnectedAccounts();
        }
        setShowDelete(false);
    };

    const handleCallback = (data: any) => {
        setShowConnect(data);
        getAllIntegrationList();
        getConnectedAccounts();
        handleCloseConnect();
        getConnectedAccounts()
    };
    const handleCloseConnect = () => setShowConnect(false);
    const getConnectedAccounts = () => {
        setLoader(true);
        return WebService.getAPI({
            action: `connector/connected-accounts?client_id=` + params.id,
            body: null,
            isShowError: true,
            access_token:access_token,
            t_id:t_id
        })
            .then((res: any) => {
                setTotalConnector(res?.client?.numberOfConnectors);
                setTotalSms(res?.client?.numberOfSMS);
                setTotalEmail(res?.client?.numberOfUsers);
                setTotalWebsite(res?.client?.numberOfWebsites);

                setTotalConnectorUsed(res?.connectorCount);
                setTotalEmailUsed(res?.emailCount);
                setTotalSmsUsed(res?.smsCount);
                setTotalWebsiteUsed(res?.websiteCount);
                // setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                return error;
            });
    };
    const getAllIntegrationList = () => {
        // setLoader(true);
        return WebService.getAPI({
            action: `connector/list?client_id=` + params.id,
            body: null,
            isShowError: true,
            access_token:access_token,
            t_id:t_id
        })
            .then((res: any) => {
                if (res.length !== 0) {
                    let tempSocial: any = [];
                    let tempIntegration: any = [];
                    res.forEach((item: any) => {
                        if (
                            [
                                "FACEBOOK",
                                "X",
                                "LINKEDIN",
                                "INSTAGRAM",
                                "TEAMS",
                                "ZOOM",
                            ].includes(item.type)
                        ) {
                            tempSocial.push(item);
                        } else {
                            tempIntegration.push(item);
                            if (item.type === "SMS") {
                                setSmsKeywordList(item.list)
                            }
                        }
                    });
                    setIntegrationDetailsList(tempIntegration);
                    setIntegrationSocialDetailsList(tempSocial);
                } else {
                    setIntegrationDetailsList([]);
                    setIntegrationSocialDetailsList([]);
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                return error;
            });
    };


    let idArray: any[] = [];

    const handleCheckboxChange = (clickedPost: any) => {
        // if (clickedPost.check === false || clickedPost.check === undefined) {
        //     var arr = [...deleteKeywordList];
        //     arr.push(clickedPost.id);
        //     setDeleteKeywordList(arr);

        //     if (deleteKeywordList.length === smsKeywordList.length) {
        //         setAllSelect(true);
        //     }
        // } else {
        //     var arr = deleteKeywordList.filter((id) => id !== clickedPost.id);
        //     setDeleteKeywordList(arr);
        //     setAllSelect(false);
        // }
        // setSmsKeywordList((prevList) =>
        //     prevList.map((post) =>
        //         post.id === clickedPost.id ? { ...post, check: !post.check } : post
        //     )
        // );

        const index = idArray.indexOf(clickedPost.id);
        if (index !== -1) {
            idArray.splice(index, 1);
        } else {
            idArray.push(clickedPost.id);
        }

    };


    return (
        <div className="page-contetn page-integration">
            <div className="d-flex justify-content-between align-items-center">
                <PageTitle Title="Export" />
            </div>
            <Col md={12} className="d-flex gap-3 justify-content-end mb-3">
                <Button
                    id="connect-btn"
                    className="btn-brand-1 text-nowrap"
                >
                    <TbCloudUpload size={18} className="me-1" />
                    Export
                </Button>
            </Col>
            <Card className="table-container" style={{ padding: "15px" }}>

                {!loader ? (
                    <>
                        {integrationDetailList.length > 0 ||
                            integrationSocialDetailList.length > 0 ? (
                            <>
                                <div>
                                    {integrationDetailList &&
                                        integrationDetailList.length > 0 &&
                                        integrationDetailList.map((integration: any) => {
                                            return (
                                                <>
                                                    <div className="text-end mb-2 me-5 mt-2 social-buy-btn-margin1">
                                                        {/* {integration.type === "SMS" ? <span className="me-2 ">
                                                            {totalSmsUsed}/{totalSms}
                                                        </span> : integration.type === "WEBSITE" ? <span className="me-2 ">
                                                            {totalWebsiteUsed}/{totalWebsite}
                                                        </span> : integration.type === "EMAIL" ? <span className="me-2 ">
                                                            {totalEmailUsed}/{totalEmail}
                                                        </span> : ""} */}

                                                    </div>
                                                    <Card className="inetegration-card border">
                                                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                                                            <div className="d-flex gap-2 align-items-center">
                                                                <img
                                                                    src={
                                                                        integration.type === "SMS"
                                                                            ? smsLogo
                                                                            : integration.type === "WEBSITE"
                                                                                ? webLogo
                                                                                : integration.type === "EMAIL"
                                                                                    ? EmailLogo
                                                                                    : ""
                                                                    }
                                                                    onError={(error: any) => {
                                                                        error.target.src = NoImage;
                                                                    }}
                                                                    width={30}
                                                                    alt=""
                                                                />
                                                                <span className=" font-medium">
                                                                    {integration.type !== "SMS"
                                                                        ? HelperService.titleCase(integration.type)
                                                                        : integration.type}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="border-start col-md-10">
                                                            <table className="integration-table">
                                                                <tr>
                                                                    <td width="16.66%">
                                                                        {/* <input
                                                                            type="checkbox"
                                                                            className="me-2"
                                                                            checked={channel.check}
                                                                            id={channel.id}
                                                                            onChange={() => {
                                                                                handleCheckboxChange(channel);
                                                                            }}
                                                                        /> */}
                                                                        <span className="font-14 text-secondary font-bold">
                                                                            {integration.type === "SMS"
                                                                                ? "Platform Type"
                                                                                : "Name"}
                                                                        </span>{" "}
                                                                    </td>
                                                                    <td width="16.66%">
                                                                        {" "}
                                                                        <span className="font-14 text-secondary font-bold">
                                                                            User Name
                                                                        </span>
                                                                    </td>
                                                                    <td width="16.66%">
                                                                        {" "}
                                                                        <span className="font-14 text-secondary font-bold">
                                                                            Email
                                                                        </span>
                                                                    </td>
                                                                    {integration.type === "SMS" ? (
                                                                        <td width="16.66%">
                                                                            <span className="font-14 text-secondary font-bold">
                                                                                Ref Id
                                                                            </span>
                                                                        </td>
                                                                    ) : (
                                                                        <td width="16.66%"></td>
                                                                    )}
                                                                    <td width="16.66%" className="text-center">
                                                                        {" "}
                                                                        <span className="font-14 text-secondary font-bold ">
                                                                            Account Type
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                {integration.list &&
                                                                    integration.list.length > 0 &&
                                                                    integration.list.map((channel: any) => {
                                                                        return (
                                                                            <tr>
                                                                                {integration.type === "SMS" ? (
                                                                                    <td width="16.66%">
                                                                                        {" "}
                                                                                        <span className="text-brand font-14">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="me-2"
                                                                                                checked={channel.check}
                                                                                                id={channel.id}
                                                                                                onChange={() => {
                                                                                                    handleCheckboxChange(channel);
                                                                                                }}
                                                                                            />
                                                                                            {channel.platformType}
                                                                                        </span>
                                                                                    </td>
                                                                                ) : (
                                                                                    <td width="16.66%">
                                                                                        <span className="text-brand font-14 text-nowrap">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="me-2"
                                                                                                checked={channel.check}
                                                                                                id={channel.id}
                                                                                                onChange={() => {
                                                                                                    handleCheckboxChange(channel);
                                                                                                }}
                                                                                            />
                                                                                            <TooltipCustom
                                                                                                st={
                                                                                                    <span>
                                                                                                        {HelperService.truncate(
                                                                                                            HelperService.removeUnderScore(
                                                                                                                channel.name
                                                                                                            ),
                                                                                                            25
                                                                                                        )}
                                                                                                    </span>
                                                                                                }
                                                                                                message={channel.name}
                                                                                                position="top"
                                                                                                id="c_btn"
                                                                                            ></TooltipCustom>
                                                                                        </span>
                                                                                    </td>
                                                                                )}
                                                                                <td>
                                                                                    {channel.user?.firstName +
                                                                                        " " +
                                                                                        channel.user?.lastName}
                                                                                </td>
                                                                                <td>{channel.user?.email}</td>
                                                                                {integration.type === "WEBSITE" ? (
                                                                                    <td width="16.66%"></td>
                                                                                ) : integration.type === "EMAIL" ? (
                                                                                    <td width="16.66%"></td>
                                                                                ) : (
                                                                                    <td>
                                                                                        <TooltipCustom
                                                                                            st={
                                                                                                <span>
                                                                                                    {HelperService.truncate(
                                                                                                        channel.refId,
                                                                                                        15
                                                                                                    )}
                                                                                                </span>
                                                                                            }
                                                                                            message={channel.refId}
                                                                                            position="top"
                                                                                            id="c_btn"
                                                                                        ></TooltipCustom>
                                                                                    </td>
                                                                                )}


                                                                                <td
                                                                                    className="text-center"
                                                                                    width="16.66%"
                                                                                >
                                                                                    <span className="status-badge active">
                                                                                        {channel.accountType}
                                                                                    </span>
                                                                                </td>

                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </table>
                                                        </div>
                                                    </Card>
                                                </>
                                            );
                                        })}
                                </div>
                                {integrationSocialDetailList &&
                                    integrationSocialDetailList.length > 0 ? (
                                    <Card className="p-1 border-0">
                                        <div className="text-end me-5 mb-2 mt-2 social-buy-btn-margin1">
                                            {/* <span className="me-2">
                                                {totalConnectorsUsed}/{totalConnectors}
                                            </span> */}
                                        </div>
                                        {integrationSocialDetailList.map((integration: any) => {
                                            return (
                                                <>
                                                    <Card className="inetegration-card border">
                                                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                                                            <div className="d-flex gap-2 align-items-center">
                                                                <img
                                                                    src={
                                                                        integration.type === "FACEBOOK"
                                                                            ? fbLogo
                                                                            : integration.type === "X"
                                                                                ? XLogo
                                                                                : integration.type === "LINKEDIN"
                                                                                    ? linkdnLogo
                                                                                    : integration.type === "INSTAGRAM"
                                                                                        ? instaLogo
                                                                                        : integration.type === "ZOOM"
                                                                                            ? ZoomLogo
                                                                                            : integration.type === "TEAMS"
                                                                                                ? TeamsLogo
                                                                                                : ""
                                                                    }
                                                                    onError={(error: any) => {
                                                                        error.target.src = NoImage;
                                                                    }}
                                                                    width={30}
                                                                    alt=""
                                                                />
                                                                <span className=" font-medium">
                                                                    {integration.type !== "LINKEDIN"
                                                                        ? HelperService.titleCase(integration.type)
                                                                        : "LinkedIn"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="border-start col-md-10">
                                                            <table className="integration-table">
                                                                <tr>
                                                                    <td width="16.66%">
                                                                        <span className="font-14 text-secondary text-nowrap">
                                                                            {/* <input
                                                                                type="checkbox"
                                                                                className="me-2"
                                                                            checked={channel.check}
                                                                            id={channel.id}
                                                                            onChange={() => {
                                                                                handleCheckboxChange(channel);
                                                                            }}
                                                                            /> */}
                                                                            Connected Accounts
                                                                        </span>
                                                                    </td>
                                                                    <td width="16.66%"></td>
                                                                    <td width="16.66%"></td>
                                                                    <td
                                                                        width="16.66%"
                                                                        className="text-center me-2"
                                                                    ></td>
                                                                </tr>
                                                                {integration.list &&
                                                                    integration.list.length > 0 &&
                                                                    integration.list.map((channel: any) => {
                                                                        return (
                                                                            <tr>
                                                                                <td width="16.66%">
                                                                                    <span className="text-brand font-14">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="me-2"
                                                                                            checked={channel.check}
                                                                                            id={channel.id}
                                                                                            onChange={() => {
                                                                                                handleCheckboxChange(channel);
                                                                                            }}
                                                                                        />
                                                                                        <TooltipCustom
                                                                                            st={
                                                                                                <span>
                                                                                                    {HelperService.truncate(
                                                                                                        HelperService.removeUnderScore(
                                                                                                            channel.name
                                                                                                        ),
                                                                                                        50
                                                                                                    )}
                                                                                                </span>
                                                                                            }
                                                                                            message={channel.name}
                                                                                            position="top"
                                                                                            id="c_btn"
                                                                                        ></TooltipCustom>
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    {channel.user?.firstName +
                                                                                        " " +
                                                                                        channel.user?.lastName}
                                                                                </td>
                                                                                <td>{channel.user?.email}</td>
                                                                                <td>
                                                                                    <TooltipCustom
                                                                                        st={
                                                                                            <span>
                                                                                                {HelperService.truncate(
                                                                                                    channel.refId,
                                                                                                    15
                                                                                                )}
                                                                                            </span>
                                                                                        }
                                                                                        message={channel.refId}
                                                                                        position="top"
                                                                                        id="c_btn"
                                                                                    ></TooltipCustom>
                                                                                </td>

                                                                                <td
                                                                                    className="text-center"
                                                                                    width="16.66%"
                                                                                >
                                                                                    <span className="status-badge active">
                                                                                        {channel.accountType}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </table>
                                                        </div>
                                                    </Card>
                                                </>
                                            );
                                        })}
                                    </Card>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            <div className="table-wrap position-relative">
                                <div className="no-data-flound bg-white py-5 rounded-4">
                                    <div className="text-center">
                                        <img src={NoDataFound} width={110} className="mb-3" />
                                        <h3 className="text-secondary font-12">
                                            No channels are connected yet
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <TableLoader />
                )}
            </Card>
        </div>

    );
};
export default ExportData;



