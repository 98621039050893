import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import Password from '../Password/Password';
import { Label } from '../Label/Label';
import WebService from '../../../Services/WebService';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';


interface PropData {
    parentCallbck?: any;
}

const ChangePass = (props: PropData) => {
    const access_token = useSelector((state: any) => state.aT.aT);
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        control: control2,
        reset: reset2,
        setValue: setValue2,
        clearErrors: clearErrors2,
        setError: setError2,
        watch: watch2 } = useForm();
    var watchVariable2 = watch2();

    const handlePassChange = (value: any, type: any) => {
        if (type === "oldpassword") {
            watchVariable2.oldPassword = value;
            setValue2("oldPassword", value);
            clearErrors2("oldPassword");
            if (watchVariable2.oldPassword === "") {
                setError2("oldPassword", { type: "custom", message: "" });
            }
        } else if (type === "newPassword") {
            watchVariable2.newPassword = value;
            setValue2("newPassword", value);
            clearErrors2("newPassword");
            if (watchVariable2.newPassword === "") {
                setError2("newPassword", {
                    type: "custom",
                    message: "Please enter new password",
                });
            }
        } else if (type === "cpassword") {
            watchVariable2.cpassword = value;
            setValue2("cpassword", value);
            clearErrors2("cpassword");
            if (watchVariable2.cpassword === "") {
                setError2("cpassword", {
                    type: "custom",
                    message: "Please enter confi password",
                });
            }
        }
    };

    const handleChangePassword = () => {
        const isLengthValid = watchVariable2.newPassword.length < 6;
        if (isLengthValid) {
            setError2("newPassword", {
                type: "custom",
                message: "Password length must be greater than 6 digit",
            });
            return;
        }
        if (watchVariable2.newPassword !== watchVariable2.cpassword) {
            setError2("cpassword", {
                type: "custom",
                message: "Those passwords didn’t match. Try again.",
            });
            return;
        }
        WebService.addLoader("save-btn");
        return WebService.putAPI({
            action: `change/password?`,
            body: watchVariable2,
            isShowError: true,
            access_token:access_token,
            t_id:t_id
        })
            .then((res: any) => {
                WebService.removeLoader("save-btn");
                toast.success(res.message);
                props.parentCallbck(false);
            })
            .catch((error: any) => {
                WebService.removeLoader("save-btn");
                return error;
            });
    };

    const handleError = (errors: any) => {
        return false;
    };

    return (
        <>
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>Reset Temporary Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        className="w-100"
                        name="Verify"
                        id="Verify"
                        onSubmit={handleSubmit2(handleChangePassword, handleError)}
                    >

                        <div className="px-3 pt-3">
                            <div className="input-group mb-2 mt-1">
                                <Password
                                    requiredMsg={{
                                        ...register2("newPassword", {
                                            required: "Please enter new password",
                                            pattern: {
                                                value:
                                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*-])(?=.*[a-zA-Z@#$%^&+=!*-]).{6,}$/,
                                                message:
                                                    "Please enter valid password that satisfy below criteria"
                                            },
                                        }),
                                    }}
                                    inputFieldClassName="form-control border-end-0 border-start-0 ps-0"
                                    id=""
                                    name=""
                                    label="Password"
                                    placeholder="New Password"
                                    method={(event: any) => {
                                        handlePassChange(event.target.value, "newPassword");
                                    }}
                                    onError={
                                        errors2.newPassword ? (
                                            <div className="mb-3">
                                                <Label
                                                    title={
                                                        errors2.newPassword.message
                                                            ? errors2.newPassword.message.toString()
                                                            : ""
                                                    }
                                                    modeError={true}
                                                    showStar={true}
                                                    type="password"
                                                /></div>
                                        ) : (
                                            ""
                                        )
                                    }
                                />{" "}
                            </div>
                            <div className="input-group mb-2 mt-0">
                                <Password
                                    requiredMsg={{
                                        ...register2("cpassword", {
                                            required: "Please enter confirm password",
                                        }),
                                    }}
                                    inputFieldClassName="form-control border-end-0 border-start-0 ps-0"
                                    id=""
                                    name=""
                                    label="Password"
                                    placeholder="Confirm Password"
                                    method={(event: any) => {
                                        handlePassChange(event.target.value, "cpassword");
                                    }}
                                    onError={
                                        errors2.cpassword ? (
                                            <Label
                                                title={
                                                    errors2.cpassword.message
                                                        ? errors2.cpassword.message.toString()
                                                        : ""
                                                }
                                                modeError={true}
                                                showStar={true}
                                                type="password"
                                            />
                                        ) : (
                                            ""
                                        )
                                    }
                                />
                            </div>
                        </div>
                        {
                            <div className="font-12 text-danger px-3 mb-3">
                                Your password must meet the following criteria:<br></br>
                                <div className="px-3">
                                    - Minimum length of 6 characters.<br></br>
                                    - At least 1 uppercase letter.<br></br>
                                    - At least 1 lowercase letter.<br></br>
                                    - At least 1 number.<br></br>
                                    - At least 1 Special character.<br></br>
                                    - Special characters allowed: ! @ # $ % ^ & * - + =<br></br>
                                </div>
                            </div>
                        }

                        <div className="px-3">
                            <Button className="btn-brand-1 w-100" type="submit" id="save-btn">
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>

            </Modal>
        </>
    )
}
export default ChangePass;