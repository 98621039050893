import { ProgressBar, Spinner } from 'react-bootstrap';
import './FullPageLoader.scss';
import { useSelector } from 'react-redux';
const FullPageLoader = (props: any) => {
  const per = useSelector(
    (state: any) => state.pI.progressBar ?? 0
  );
  return (
    <>
      {props.show ? <div className="loading">
        <Spinner animation="border" className='uil-ring-css' />
        {/* <span>
          <span>Uploading....</span>
          <ProgressBar now={per} animated label={`${per}%`} />
        </span> */}
      </div> : ""}

    </>
  );
};

export default FullPageLoader;
