import { useState } from 'react';
import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store, { persistor } from "./Redux/store";
import { PersistGate } from 'redux-persist/integration/react';

const App = () => {  
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <Navigation />
            <ToastContainer />            
          </div>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;












