import { legacy_createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk'
import { composeWithDevTools } from "redux-devtools-extension";
// import { userLoginReducer } from "../reducer/AuthReducer";
// import { addSideBarReducer, sideBarReducer } from "../reducer/SideBarReducer";
// import { getSdMaster, getSalesData, getAddressData, SearchResultredux-devtools-extensionReducer, getPageTitle, getActivePage, getAdditionalInfo, getCustomerInfoModal, getAddress, getAttachEquipmment, activeTab, getDictionary, getWorkOrderId, getInvoiceData, getOverviewCount, getInvoiceSDMaster, getInvoiceOverview, getDefaults, getCallTimeEntryId, getIsRefresh, getDispatchBoardDate, getSdBilling, getContractData, getUpdateOverview } from "../reducer/CommonReducer";

const reducers = combineReducers({
    
})

const initialState = {}

const middleware = [thunk]

const store = legacy_createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;

export type RootState = ReturnType<typeof store.getState>