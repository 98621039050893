import React from "react";
import { Button } from "react-bootstrap";
import WebService from "../../Services/WebService";
import { useSelector } from "react-redux";

const TimeFrameSelector = (props) => {
  const { actionProvider } = props;
  const handleSelection = (timeFrame) => {   
    const endDate = new Date();
    let startDate;

    switch (timeFrame) {
      case "Last 30 days":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        break;
      case "Last 90 days":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 90);
        break;
      case "Current year":
        startDate = new Date(endDate.getFullYear(), 0, 1);
        break;
      case "Last year":
        startDate = new Date(endDate.getFullYear() - 1, 0, 1);
        endDate.setFullYear(endDate.getFullYear() - 1, 11, 31);
        break;       
      default:
        startDate = new Date();
    }

    WebService.addLoader(timeFrame);
    setTimeout(() => {
      WebService.removeLoader(timeFrame);
      actionProvider.handleStartReview(startDate.toISOString(),endDate.toISOString(), props?.payload?.sample_rate, props?.payload?.channel_list);
    }, 2000);
    
  };

  const handleCustomSelection = () => {
    WebService.addLoader("Custom");
    setTimeout(() => {
      WebService.removeLoader("Custom");
      actionProvider.handleFromDate("Custom",props?.payload?.sample_rate, props?.payload?.channel_list);
    }, 2000);
    
  }

  return (
    <div className="react-chatbot-kit-chat-bot-message">
      <Button
        id="Last 30 days"
        className="btn-brand-light mt-2"
        onClick={() => handleSelection("Last 30 days")}
      >
        Last 30 days
      </Button>
      <br />
      <Button
        id="Last 90 days"
        className="btn-brand-light mt-2"
        onClick={() => handleSelection("Last 90 days")}
      >
        Last 90 days
      </Button>
      <br />
      <Button
        id="Current year"
        className="btn-brand-light mt-2"
        onClick={() => handleSelection("Current year")}
      >
        Current year
      </Button>
      <br />
      <Button
        id="Last year"
        className="btn-brand-light mt-2"
        onClick={() => handleSelection("Last year")}
      >
        Last year
      </Button>
      <br />
      <Button
        id="Custom"
        className="btn-brand-light mt-2"
        onClick={() => handleCustomSelection()}
      >
        Custom
      </Button>
    </div>
  );
};

export default TimeFrameSelector;

