import { useState } from "react";
import { Container, Navbar, Dropdown, Alert, ProgressBar, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/ai-logo.png";
import avtar from "../../assets/images/default-avatar.jpg";
import { SlUser } from "react-icons/sl";
import { GoChevronDown } from "react-icons/go";
import { RiLogoutCircleLine } from "react-icons/ri";
import WebService from "../../Services/WebService";
import { useEffect } from "react";
import missingImage from "../../assets/images/profile-placeholder.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { aiM, updateProfileImage } from "../../Redux/actions";
import { HiOutlineFolderDownload } from "react-icons/hi"
import HelperService from "../../Services/HelperService";
import { MdOutlineDownloadForOffline, MdOutlineDownloading } from "react-icons/md"
import { TooltipCustom } from "../Common/Tooltip/Tooltip";
import InfiniteScroll from 'react-infinite-scroll-component';


const Header = () => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const role = useSelector((state: any) => state.uR.role);
  const userName = useSelector((state: any) => state.uN.name);
  const userEmail = useSelector((state: any) => state.uE.email);
  const access_token = useSelector((state: any) => state.aT.aT);
  const dispatch = useDispatch();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [count, setCount] = useState(0);
  const [downloadFileList, setDownloadFileList] = useState<any[]>([]);
  const profileImage = useSelector(
    (state: any) => state.pI.profileImage ?? missingImage
  );
  let per = useSelector(
    (state: any) => state.pI.progressBar ?? 0
  );
  const [page, setPage] = useState<number>(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (per == 100.00) {
      setTimeout(() => {
        setShowProgressBar(false);
      }, 3000);
    } else {
      setShowProgressBar(true);
    }
  }, [per]);


  useEffect(() => { }, [role]);
  const meCall = () => {
    return WebService.getAPI({
      action: `me`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        // dispatch(aiM(res.client?.aiModel?.aiModelName));
        dispatch(updateProfileImage(res.profilePicture ?? missingImage));
        localStorage.setItem(
          "pI",
          res.profilePicture ?? missingImage
        );
      })
      .catch((error) => {
        return error;
      });

  };

  // const getDownloadManagerList = () => {
  //   let obj = {
  //     page: "1",
  //     from_date: "",
  //     to_date: ""
  //   };
  //   var qurey = new URLSearchParams(obj).toString();
  //   return WebService.getAPI({
  //     action: `download/manager/list?page=1`,
  //     body: null,
  //     access_token: access_token,
  //     t_id: t_id
  //   })
  //     .then((res: any) => {
  //       setDownloadFileList(res.list)
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // };


  const getDownloadManagerList = (pg: any) => {
    console.log(hasMore);

    let obj = {
      page: pg.toString(),
      from_date: "",
      to_date: ""
    };
    var query = new URLSearchParams(obj).toString();

    return WebService.getAPI({
      action: `download/manager/list?${query}`,
      body: null,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (res?.list?.length > 0) {
          setDownloadFileList((prevList) => [...prevList, ...res.list]);
        }
        if (res.nextOffset === -1) {
          setHasMore(false);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        setHasMore(false);
        console.error("Error fetching data:", error);
      });
  };



  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Navbar expand="lg">
            <Navbar.Brand className="me-5">
              <img src={Logo} width={200} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex align-items-center ms-auto gap-3">
              {
                per !== 0 && showProgressBar ?
                  <div className="border rounded-pill px-3 py-2 h-100 text-center">
                    <div className="font-12 mb-1" style={{ width: "150px" }}>{per != 100.00 ? "Uploading..." + per + "%" : `Uploaded`}</div>
                    <ProgressBar striped className="uploading-progress" variant="danger" now={per} />
                  </div>
                  : ""
              }
              {
                role !== "BASIC_USER" &&
                <Dropdown className="download-dd"
                  onClick={() => { setPage(1); setHasMore(true); setDownloadFileList([]); getDownloadManagerList(1) }} id="downloadManager"
                >
                  <Dropdown.Toggle >
                    <HiOutlineFolderDownload style={{ color: "black" }} size={20} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" id="download_manger">
                    <div className="px-3"> <span className="font-14 font-500 text-dark">Download Manager</span></div>
                    <Dropdown.Divider className="mb-0" />
                    <InfiniteScroll
                      scrollableTarget="download_manger"
                      dataLength={downloadFileList.length}
                      next={() => getDownloadManagerList(page)}
                      hasMore={hasMore}
                      loader={
                        <Dropdown.Item className="text-center">
                          <Spinner animation="border" variant="primary" />
                        </Dropdown.Item>
                      }
                      endMessage={
                        <Dropdown.Item className="text-center">
                          <b>No more downloads available</b>
                        </Dropdown.Item>
                      }
                    >
                      {downloadFileList?.length > 0
                        ? downloadFileList.map((item: any, index: any) => (
                          <Dropdown.Item key={"dwnld_" + index}>
                            <div
                              className="d-flex justify-content-between gap-2"
                              onClick={() =>
                                item.downloadStatus === "COMPLETED"
                                  ? window.open(item.downloadPath)
                                  : ""
                              }
                            >
                              <div>
                                <p className="mb-0 font-14">
                                  {item.fileName ? (
                                    <TooltipCustom
                                      st={
                                        <span>
                                          {HelperService.truncate(item.fileName, 30)}
                                        </span>
                                      }
                                      message={item.fileName}
                                      position="top"
                                      id="c_btn"
                                    ></TooltipCustom>
                                  ) : (
                                    `${item.requestId}.7z`
                                  )}
                                </p>
                                <p className="mb-0 font-12 text-secondary">
                                  {HelperService.getFormatedDateForDetail(
                                    item.createdDate,
                                    "MM-DD-YYYY  hh:mm A"
                                  )}
                                </p>
                                <p className="mb-0 font-12 text-secondary">
                                  {item?.fileType?.toUpperCase()}
                                </p>
                              </div>
                              <div>
                                {item.downloadStatus === "COMPLETED" ? (
                                  <a href="">
                                    <MdOutlineDownloadForOffline size={22} />
                                  </a>
                                ) : (
                                  <TooltipCustom
                                    st={
                                      <a href="" className="opacity-50">
                                        <MdOutlineDownloading size={22} />
                                      </a>
                                    }
                                    message="Still in progress"
                                    position="top"
                                    id="c_btn"
                                  ></TooltipCustom>
                                )}
                              </div>
                            </div>
                          </Dropdown.Item>
                        ))
                        : (
                          <Dropdown.Item className="text-center">
                            <span className="font-14">No Download Available</span>
                          </Dropdown.Item>
                        )}

                    </InfiniteScroll>
                  </Dropdown.Menu>
                </Dropdown>
              }
              <Dropdown className="profile-dd">
                <Dropdown.Toggle>
                  <div className="d-flex gap-2">
                    <div>
                      <img
                        src={profileImage}
                        width={35}
                        height={35}
                        className=" rounded-circle object-fit-cover"
                        alt=""
                        onError={(error: any) => {
                          if (count === 0) {
                            meCall();
                            setCount(1);
                          } else {
                            error.target.src = missingImage;
                            error.target.className =
                              "w-100 object-fit-cover border";
                          }
                        }}
                      />
                    </div>
                    <div>
                      <p className="font-14 mb-0 text-dark font-medium">
                        {userName}
                      </p>
                      <p className="font-12 mb-0 text-secondary">
                        {userEmail}
                      </p>
                    </div>
                  </div>
                  <GoChevronDown size={16} className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Link to="/profile" className=" dropdown-item">
                    {" "}
                    <SlUser size={14} className="me-2" /> My Profile
                  </Link>
                  <Link
                    to="/login"
                    className=" dropdown-item"
                    onClick={() => {
                      WebService.logout();
                    }}
                  >
                    <RiLogoutCircleLine size={14} className="me-2" /> Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Header;










