
import React from 'react';

const CustomHeader = () => {
  return (
    <div className='react-chatbot-kit-chat-header'>
      <p>AI Compliance Bot</p>
    </div>
  );
};

export default CustomHeader;
