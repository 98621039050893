import moment from "moment";
import timez from "moment-timezone";
import { useSelector } from "react-redux";
const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    var stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc)
          .local()
          .format(
            "MM-DD-YYYY  hh:mm A "
          ) + "(" + timez.tz(timez.tz.guess()).format('z') + ")";
        // ;
      }
    }
  },


  getFormatedDateForDetailNonUTC(dt: any, format?: any) {
    var stillUtc = moment(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).format(format);
      } else {
        return moment(stillUtc)
          .format(
            "MM-DD-YYYY  hh:mm A"
          );
      }
    }
  },


  removeUnderScore(str: String) {
    if (str)
      if (str.includes("_")) {
        str = this.titleCase(str);
        var str1 = str.replaceAll("_", " ");
        return str1;
      } else {
        if (str == "Sms") return str.toUpperCase();
        else return str;
      }
  },

  titleCase(str: any) {
    if (str) {
      var str1 = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return str1;
    }
  },
  truncate(str: any, limit: number) {
    if (str) {
      var str1 = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return str1.length > limit ? str1.substring(0, limit) + "..." : str1;
    }
  },
  getFormatedNumber(dt: Number, prefix: any, suffix: any) {
    if (dt) {
      if (prefix) {
        return (prefix ? prefix : "") + "" + dt;
      } else {
        return dt + "" + (suffix ? suffix : "");
      }
    } else {
      if (prefix) {
        return (prefix ? prefix : "") + "" + 0;
      } else {
        return 0 + "" + (suffix ? suffix : "");
      }
    }
  },
  abbrNum(number: any, decPlaces: any) {
    if (!number) {
      return number;
    }
    // number = number.toFixed(2);
    decPlaces = Math.pow(10, decPlaces);
    var abbrev = ["k", "m", "b", "t"];
    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        number += abbrev[i];
        break;
      } else {
        number = Math.round(number);
      }
    }
    return number;
  },
  getFormatedStatusText(dt: string) {
    if (dt != "") {
      if (dt.includes("_")) {
        dt = dt.replaceAll("_", " ");
      }
      dt = dt[0].toUpperCase() + dt.slice(1).toLowerCase();
      return dt;
    } else {
      return "";
    }
  },
  capitalize(word: any) {
    return word
      .toLowerCase()
      .replace(/\w/, (firstLetter: any) => firstLetter.toUpperCase());
  },
  getHighlightedContent(content: any, highlighter: any, type?: any) {
    if (content !== undefined && highlighter != undefined && highlighter != null && highlighter != "") {
      highlighter = highlighter.replaceAll('][', ",");
      var keywords = JSON.parse(highlighter);

      var data = "<p>" + content + "</p>";
      if (localStorage.getItem("pto") === "B" || localStorage.getItem("pto") === "L") {
        const parser = new DOMParser();
        const htmlDoc: any = parser.parseFromString(data, 'text/html');
        const body = htmlDoc.getElementsByTagName("BODY")[0]
        keywords.forEach((element: any) => {
          this.highlightTextAcrossNodes(unescape(element.answer.trim()), body)
        });
        data = htmlDoc.getElementsByTagName('html')[0].innerHTML;
        return data;
      } else {
        if (keywords.length > 0) {
          keywords.forEach((element: any) => {
            if (element.word != "") {
              // 
              var regex = new RegExp('(?<![\w\d])' + element.word + '(?![\w\d])', 'g');
              data = data.replaceAll(regex, '<span style="border-radius: 6px;color: #000;background: #fff0c5;">' + element.word + '</span>');
              data = data.replaceAll((element.word).toLowerCase(), '<span style="border-radius: 6px;color: #000;background: #fff0c5;">' + element.word + '</span>')
              data = data.replaceAll((element.word).toUpperCase(), '<span style="border-radius: 6px;color: #000;background: #fff0c5;">' + element.word + '</span>')
              data = data.replaceAll(this.capitalize(element.word), '<span style="border-radius: 6px;color: #000;background: #fff0c5;">' + element.word + '</span>')
            }
          });
          return data;
        }
      }
      return content;
    } else {
      return content;
    }
  },

  updateLinkedIn(content: any) {
    content = content.replaceAll(("hashtag|\\#|"), '#')
    content = content.replace(/[^urn:li:person:[A-Za-z0-9]]/g, '')
    content = content.replace(/[^urn:li:organization:[A-Za-z0-9]]/g, '')
    // userInput.replace(/[^a-zA-Z0-9-_]/g, '');
    // content = content.replaeplaceAll(this.capitalize(element.word), '<span style="border-radius: 6px;color: #000;background: #fff0c5;">' + element.word + '</span>')
    return content;

  },

  convertDateToISO(dateString: string) {
    // Parse the date string
    const parsedDate = new Date(dateString);

    // Format the date to ISO 8601 format
    const isoDate = parsedDate.toISOString();

    return isoDate;
  },










  highlightTextAcrossNodes(textToHighlight: any, node1: any) {
    console.log(textToHighlight);

    textToHighlight = textToHighlight.replaceAll("\\n", " ")
    const wordsToHighlight = textToHighlight.split(' '); // Split the search text into words
    console.log(wordsToHighlight);
    const textNodes: any[] = [];
    function collectTextNodes(node: any) {
      if (node.nodeType === 3) { // Node.TEXT_NODE
        textNodes.push(node);
      } else if (node.nodeType === 1) { // Node.ELEMENT_NODE
        node.childNodes.forEach(collectTextNodes);
      }
    }

    // Start collecting from the body element
    collectTextNodes(node1);

    let startIndex = 0; // Start index of the current match
    let foundIndex = -1; // Index where the last match was found
    let nodesToHighlight: any[] = []; // Nodes to highlight
    let matchText = ""; // Buffer to store matched text

    // Iterate over text nodes to find matching words
    for (let i = 0; i < textNodes.length; i++) {
      let textNode = textNodes[i];
      let textContent = textNode.textContent;


      // Split the text into words to match word by word
      let words = textContent.split(/\s+/);

      for (let j = 0; j < words.length; j++) {

        let word = words[j];
        // console.log(word);
        word = word.replace(/[,"<>(){}@]$/, '');
        if (word.length == 0) continue;
        // If the word matches the current word in the sequence to highlight

        if (word.trim() == wordsToHighlight[startIndex].replace(/[,"<>(){}@]$/, '').trim()) {
          console.log(word.trim() + " == " + wordsToHighlight[startIndex].replace(/[,"<>(){}@]$/, '').trim());

          if (startIndex == 0) {
            foundIndex = j; // Remember the index of the first match
            nodesToHighlight = [textNode]; // Start collecting nodes to highlight
            matchText = word; // Start building the matched text
          } else {
            matchText += " " + word; // Add to matched text
            nodesToHighlight.push(textNode);
          }
          startIndex++;
          // Check if all words are matched
          if (startIndex == wordsToHighlight.length) {
            console.log("HERE");

            this.highlightPartialMatch(nodesToHighlight, matchText);
            startIndex = 0;
            foundIndex = -1;
            matchText = "";
            nodesToHighlight = [];
          }
        } else {
          // If we find a non-matching word after some matches, reset
          if (startIndex > 0) {
            if (wordsToHighlight.length == nodesToHighlight.length) {
              this.highlightPartialMatch(nodesToHighlight, matchText);
            }
          }

          startIndex = 0;
          foundIndex = -1;
          matchText = "";
          nodesToHighlight = [];
        }
      }

    }

  },

  isOnlyWhitespaceOrTags(text: any) {
    return text.trim() === "" || /^[\s\n\r]*$/.test(text);
  },

  highlightPartialMatch(nodes: any, matchText: any) {
    let remainingText = matchText;
    console.log("HGIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII =>" + matchText);
    console.log("HGIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII =>" + nodes.length);
    for (const node of nodes) {
      const span = document.createElement('span');
      span.style.backgroundColor = 'yellow';
      console.log(node);
      if (remainingText.startsWith(node.textContent.trim())) {
        const textToHighlight = node.textContent;
        span.textContent = textToHighlight;
        console.log("ooooooooooooooooooyyyyyyyyyyyyyyyyyyyyyyyyeeeeeeeeeeeeeeeeeeeeee =>" + textToHighlight);
        if (node.parentNode != null) {
          console.log("ooooooooooooooooooyyyyyyyyyyyyyyyyyyyyyyyyeeeeeeeeeeeeeeeeeeeeee IN");
          node.parentNode.replaceChild(span, node);
          remainingText = remainingText.slice(textToHighlight.length).trim();
          console.log("HGJJJJJJJJJJJJJJJJJJJJJJJJJJJJ =>" + remainingText);
          if (remainingText.length == 0) break; // Stop if no remaining text to highlight
        }

      } else {
        console.log("ooooooooooooooooooyyyyyyyyyyyyyyyyyyyyyyyyeeeeeeeeeeeeeeeeeeeeee ELSE");
        // const commonWords = this.findCommonWords(remainingText.replace(/[,"<>(){}@]$/, ''), node.textContent.trim().replace(/[,"<>(){}@]$/, ''));
        let text = node.textContent.trim();
        console.log("o1 => " + text);
        text = text.replaceAll("  ", " ");
        text = text.replaceAll("\n", "");
        text = text.replaceAll("\r", "");
        console.log("o2 => " + text);
        console.log("ooooooooooooooooooyyyyyyyyyyyyyyyyyyyyyyyyeeeeeeeeeeeeeeeeeeeeee ELSE1 =>" + remainingText.replace(/[,"<>(){}@]$/, ''));
        const index = text.replace(/[,"<>(){}@]$/, '').indexOf(remainingText.replace(/[,"<>(){}@]$/, ''));
        console.log("ooooooooooooooooooyyyyyyyyyyyyyyyyyyyyyyyyeeeeeeeeeeeeeeeeeeeeee ELSE2 =>" + index);
        if (index !== -1) {
          // Split the text into three parts: before, match, and after
          const beforeText = text.substring(0, index);
          const matchedText = text.substring(index, index + remainingText.length);
          const afterText = text.substring(index + remainingText.length);
          // Create text nodes for the before and after parts
          const beforeNode = document.createTextNode(beforeText);
          const afterNode = document.createTextNode(afterText);
          // Create a span element for the matched text
          const highlightSpan = document.createElement('span');
          highlightSpan.textContent = matchedText;
          highlightSpan.style.backgroundColor = 'yellow'; // Highlight color
          // Clear the container and append the new nodes
          if (node.parentNode != null) {
            console.log("ooooooooooooooooooyyyyyyyyyyyyyyyyyyyyyyyyeeeeeeeeeeeeeeeeeeeeee ELSE3 =>");
            node.textContent = ''; // Clear existing text content
            node.parentNode.appendChild(beforeNode);
            node.parentNode.appendChild(highlightSpan);
            node.parentNode.appendChild(afterNode);
          }
        }
      }
    }
  },

  findCommonWords(str1: any, str2: any) {
    // Split strings into words
    const words1 = str1.split(/\s+/);  // Split by any whitespace
    const words2 = str2.split(/\s+/);

    // Find the common words
    const commonWords = words1.filter((word: any) => words2.includes(word));

    return commonWords.join(' ');
  },


  timeAgo(timestamp: any) {
    const currentTime: any = new Date();
    const givenTime: any = new Date(timestamp);
    const differenceInSeconds = Math.floor((currentTime - givenTime) / 1000);
    const minutes = Math.floor(differenceInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(minutes / (60 * 24));

    if (minutes < 1) {
      return "Just now";
    } else if (minutes === 1) {
      return "1 minute ago";
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours === 1) {
      return "1 hour ago";
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days === 1) {
      return "1 day ago";
    } else if (days < 2) {
      return `${days} days ago`;
    } else {
      return moment(timestamp).local().format("MM-DD-YYYY hh:mm A");
    }
  },
  clearString(str: any) {

    let cleanedStr = str?.replace(/[^a-zA-Z0-9 ]/g, "");
    cleanedStr = cleanedStr?.replaceAll("  ", " ");
    return cleanedStr;
  }

};


export default HelperService;
